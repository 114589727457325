import React from "react";

import logoAWSLogo from "../../images/global/AWSlogo.png"
import logoGoogleCloud from "../../images/global/GCPlogo.png"
import logoMicrosoftAzure from "../../images/global/Azurelogo.png"

const CloudProviders = () => (
  <section className="global-cloud-providers">
    <div className="container">
      <div className="row row-flex">
        <div className="col-xs-12 col-sm-12 col-md-5"> 
          <h2>Works With Your Cloud Providers</h2>
        </div>
        <div className="provider-logo col-xs-4 col-sm-4 col-md-2"> 
          <img src={logoAWSLogo} alt="AWS Logo" width="150" height="150" />
        </div>
        <div className="provider-logo col-xs-4 col-sm-4 col-md-2"> 
          <img src={logoGoogleCloud} alt="Google Cloud Logo" width="150" height="150" />
        </div>
        <div className="provider-logo col-xs-4 col-sm-4 col-md-2"> 
          <img src={logoMicrosoftAzure} alt="Microsoft Azure Logo" width="150" height="150" />
        </div>
      </div>
    </div>
  </section>
);

export default CloudProviders;